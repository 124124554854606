<template>
  <div class="orderList">
    <el-tabs class="orderList-tab" v-model="currentTab" type="card" @tab-click="tabClicked">
      <el-tab-pane label="总订单" name="0" :disabled="loading">
        <en-table-layout :tableData="tableData.data" :loading="loading" :span-method="orderSpanMethod">
          <template slot="toolbar">
            <el-form-item label="订单状态">
              <el-select style="width: 100px" v-model="params.order_status" size="small" placeholder="请选择" clearable>
                <el-option label="全部" value="ALL" />
                <el-option label="待付款" value="CONFIRM" />
                <el-option label="待发货" value="PAID_OFF" />
                <el-option label="待收货" value="SHIPPED" />
                <el-option label="已完成" value="COMPLETE" />
                <el-option label="取消中" value="CANCEL_IN" />
                <el-option label="已取消" value="CANCELLED" />
                <!-- <el-option label="待评论" value="AFTE_COMMENT" />
                <el-option label="售后中" value="REFUND" />-->
              </el-select>
            </el-form-item>

            <el-form-item label="提交时间">
              <el-date-picker style="width: 200px" v-model="params.order_time_range" type="daterange" :editable="false"
                unlink-panels size="small" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"
                @change="handleDateChange" :picker-options="{
      disabledDate(time) {
        return time.getTime() > Date.now();
      },
      shortcuts: MixinPickerShortcuts,
    }"></el-date-picker>
            </el-form-item>
            <el-form-item label="来源应用" class="col-auto">
              <el-select style="width: 100px" v-model="params.mall_type" size="small" placeholder="请选择" clearable>
                <el-option label="全部" value="" />
                <el-option label="现金商城" :value="2" />
                <el-option label="套餐卡商城" :value="3" />
                <el-option label="智能套餐卡商城" :value="4" />
                <el-option label="双选商城" :value="5" />
                <el-option label="一件代发商城" :value="7" />
                <el-option label="小程序商城" :value="6" />
              </el-select>
            </el-form-item>
            <el-form-item label="订单类型" class="col-auto">
              <el-select style="width: 100px" v-model="params.trade_type" size="small" placeholder="请选择" clearable>
                <el-option label="全部" value="" />
                <el-option label="普通商品订单" :value="1" />
                <el-option label="礼包订单" :value="2" />
                <el-option label="计次卡订单" :value="3" />
                <el-option label="宅配卡订单" :value="4" />
              </el-select>
            </el-form-item>
            <el-form-item label="关键词">
              <el-input style="width: 300px" v-model="kw" size="small" placeholder="请输入关键字进行搜索" clearable>
                <el-select style="width: 110px;" v-model="params.key_words" slot="prepend">
                  <el-option label="订单编号" value="order_sn"></el-option>
                  <el-option label="下单账号" value="mobile_account"></el-option>
                  <el-option label="商品名称" value="goods_name"></el-option>
                  <el-option label="物流单号" value="ship_no"></el-option>
                  <el-option label="企业名称" value="enterprise_name"></el-option>
                  <el-option label="收货人手机号" value="ship_mobile"></el-option>
                  <el-option label="储值卡号" value="card_number"></el-option>
                  <el-option label="套餐卡号" value="card_code"></el-option>
                  <el-option label="计次卡号" value="card_code"></el-option>
                  <el-option label="宅配卡号" value="card_code"></el-option>
                  <el-option label="站点名称" value="shop_name"></el-option>
                  <el-option label="订单来源" value="item_source"></el-option>
                  <el-option label="卡券名称" value="card_name"></el-option>
                  <el-option label="礼包名称" value="shop_combo_name"></el-option>
                </el-select>
              </el-input>
            </el-form-item>
            <!-- <el-form-item label="关键词" v-if="currentTab === '1'">
              <el-input style="width: 300px" v-model="kw" size="small" placeholder="请输入关键字进行搜索" clearable>
                <el-select style="width: 110px;" v-model="params.key_words" slot="prepend">
                  <el-option label="子订单编号" value="order_sn"></el-option>
                  <el-option label="关联总订单号" value="trade_sn"></el-option>
                  <el-option label="买家账号" value="mobile_account"></el-option>
                  <el-option label="商品名称" value="goods_name"></el-option>
                  <el-option label="物流单号" value="ship_no"></el-option>
                  <el-option label="供应商名称" value="seller_name"></el-option>
                  <el-option label="收货人手机号" value="ship_mobile"></el-option>
                  <el-option label="订单来源" value="item_source"></el-option>
                  <el-option label="卡券名称" value="card_name"></el-option>
                  <el-option label="礼包名称" value="shop_combo_name"></el-option>
                </el-select>
              </el-input>
            </el-form-item> -->
            <el-form-item>
              <el-button @click="GET_OrderList(currentTab, 'search')" size="small" type="primary"
                :disabled="loading">搜索</el-button>
            </el-form-item>
            <div class="col"></div>
            <el-form-item>
              <el-button @click="handleExportOrders" size="small" type="primary" v-if="currentTab === '0'"
                :disabled="loading">导出全部订单</el-button>
            </el-form-item>
          </template>
          <!-- 总订单表格-->
          <template slot="table-columns">
            <el-table-column label="订单编号" width="140" fixed="left" align="center">
              <template slot-scope="{row}">
                <span class="ml-2">{{ row.trade_sn }}</span>
              </template>
            </el-table-column>

            <el-table-column label="订单总额" width="100" fixed="left" align="center">
              <template slot-scope="{row}">
                <span v-if="row.total_price_platform">
                  {{ row.total_price_platform | unitPrice('￥') }}
                </span>
                <span v-else>
                  {{ row.platform_price + row.platform_freight_price | unitPrice('￥') }}
                </span>
              </template>
            </el-table-column>

            <el-table-column label="图片" width="60" class-name="goods-cover-wrapper" fixed="left">
              <template slot-scope="{row}">
                <div style="position: relative">
                  <el-popover placement="right" trigger="hover">
                    <img :src="row.image || row.goods_image" style="width: 300px;" alt />
                    <img slot="reference" :src="row.image || row.goods_image" class="goods-cover" alt />
                  </el-popover>
                  <img class="goods-tag" :src="getRowTagImage(row)">
                </div>
              </template>
            </el-table-column>

            <el-table-column label="商品名称" show-overflow-tooltip width="200" fixed="left">
              <template slot-scope="{row}">
                <span class="ml-2">{{ row.goods_name.replaceAll('amp;', '').replaceAll(' ', '&nbsp;') }}</span>

              </template>
            </el-table-column>

            <el-table-column label="商品单价" width="100" align="center">
              <template slot-scope="{row}">
                <span v-if="row.price">{{ row.price | unitPrice('￥') }}</span>
              </template>
            </el-table-column>

            <el-table-column label="商品数量" width="80" align="center">
              <template slot-scope="{row}">
                <span class="ml-2">{{ row.goods_num }}</span>
              </template>
            </el-table-column>

            <el-table-column label="售后状态" width="80" align="center">
              <template slot-scope="{row}">{{ row.delivery_method == 1 ?
      MixinGetOrderStatus(row.service_status) : '未申请' }}</template>
            </el-table-column>

            <el-table-column label="物流状态" width="80" align="center">
              <template slot-scope="{row}">
                <span v-if="row.delivery_method !== 1">未发货</span>
                <span v-else-if="row.logistics_status_text">{{ row.logistics_status_text }}</span>
                <span v-else>{{ row.state === 2 ? '已发货' : (row.state === 3 ? '已收货' : '未发货') }}</span>
              </template>
            </el-table-column>

            <el-table-column label="物流单号" width="300" show-overflow-tooltip align="center">
              <template slot-scope="{row}">
                <template v-if="row.delivery_method !== 1">
                  {{ row.delivery_method == 2 ? '餐类自提' : '自提' }}
                </template>
                <template v-else-if="row.delivery">{{ row.delivery.logiName }}: {{ row.delivery.deliveryNo }}</template>
              </template>
            </el-table-column>
            <el-table-column label="收货人姓名" width="100" prop="consignee_name" align="center" />
            <el-table-column label="收货人手机号" width="120" prop="consignee_mobile" align="center" />
            <el-table-column label="收货地址" width="300" show-overflow-tooltip align="center">
              <template slot-scope="{row}">
                {{ row.consignee_province + row.consignee_city + row.consignee_county + row.consignee_town +
      row.consignee_address
                }}
              </template>
            </el-table-column>
            <el-table-column label="供应商名称" width="120" align="center" show-overflow-tooltip>
              <template slot-scope="{row}">
                {{ row.seller_name }}
                <div v-if="[1, 2].includes(row.hangup_status) == false && row.order_hangup_status === 3" style="color: red;">
                  (供应商挂起)</div>
              </template>

            </el-table-column>

            <el-table-column label="订单状态" width="100" align="center">
              <template slot-scope="{row}">
                <template>
                  {{ row.jd_cancel_status == 1 ? '取消中' : row.trade_status_text }}
                  <div v-if="row.hangup_status === 1" style="color: red;">(店铺挂起)</div>
                  <div v-if="row.hangup_status === 2" style="color: red;">(企业挂起)</div>
                  <div v-if="row.hangup_status === 3" style="color: red;">(供应商挂起)</div>
                </template>
              </template>
            </el-table-column>
            <el-table-column label="订单来源" align="center" width="200">
              <template slot-scope="{row}">{{ row.order_original }}</template>
            </el-table-column>
            <el-table-column label="来源应用" align="center" width="200">
              <template slot-scope="{row}">
                <span v-if="row.mall_type == 2">现金商城</span>
                <span v-if="row.mall_type == 3">套餐卡商城</span>
                <span v-if="row.mall_type == 4">AI智能套餐卡商城</span>
                <span v-if="row.mall_type == 5">双选商城</span>
                <span v-if="row.mall_type == 7">一件代发商城</span>
                <span v-if="row.mall_type == 6">小程序商城</span>
              </template>
            </el-table-column>
            <el-table-column label="订单类型" align="center" width="200">
              <template slot-scope="{row}">
                {{ row.trade_form | tradeformFilter }}
              </template>
            </el-table-column>
            <el-table-column label="下单时间" align="center" width="160">
              <template slot-scope="{row}">{{ row.create_time | unixToDate }}</template>
            </el-table-column>
            <el-table-column label="下单账号" align="center" prop="mobile" width="150" show-overflow-tooltip
              class-name="font-family-tttgb">
              <template slot-scope="{row}">
                <p>{{ row.mobile || '' }}</p>
                <p>{{ row.login_account || '' }}</p>
              </template>
            </el-table-column>
            <el-table-column label="下单备注信息" align="center" width="160">
              <template slot-scope="{row}">
                <el-tooltip class="item" effect="dark" :content="row.remark" placement="top">
                  <div class="remark-box">
                    {{ row.remark }}
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>
            <!-- 平台利润 -->
            <el-table-column label="平台利润" align="center" width="100">
              <template slot-scope="{row}">{{ row.platform_profit }}</template>
            </el-table-column>
            <el-table-column label="操作" fixed="right">
              <template slot-scope="{row}">
                <el-button type="primary" size="small" @click="handleOperateOrder(row)">查看订单</el-button>
              </template>
            </el-table-column>
          </template>
          <el-pagination v-if="tableData" slot="pagination" @size-change="handlePageSizeChange"
            @current-change="handlePageCurrentChange" :current-page="tableData.page_no" :page-size="tableData.page_size"
            :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background
            :total="tableData.data_total"></el-pagination>
        </en-table-layout>
      </el-tab-pane>
      <el-tab-pane label="精选优品子订单" name="1" :disabled="loading">
        <en-table-layout :tableData="tableData.data" :loading="loading" :span-method="orderSpanMethod">
          <template slot="toolbar">
            <el-form-item label="订单状态">
              <el-select style="width: 100px" v-model="params.order_status" size="small" placeholder="请选择" clearable>
                <el-option label="全部" value="ALL" />
                <el-option label="待付款" value="CONFIRM" />
                <el-option label="待发货" value="PAID_OFF" />
                <el-option label="待收货" value="SHIPPED" />
                <el-option label="已完成" value="COMPLETE" />
                <el-option label="已取消" value="CANCELLED" />
                <!-- <el-option label="待评论" value="AFTE_COMMENT" />
                <el-option label="售后中" value="REFUND" />-->
              </el-select>
            </el-form-item>

            <el-form-item label="提交时间">
              <el-date-picker style="width: 200px" v-model="params.order_time_range" type="daterange" :editable="false"
                unlink-panels size="small" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"
                @change="handleDateChange" :picker-options="{
      disabledDate(time) {
        return time.getTime() > Date.now();
      },
      shortcuts: MixinPickerShortcuts,
    }"></el-date-picker>
            </el-form-item>
            <el-form-item label="来源应用" class="col-auto">
              <el-select style="width: 100px" v-model="params.mall_type" size="small" placeholder="请选择" clearable>
                <el-option label="全部" value="" />
                <el-option label="现金商城" :value="2" />
                <el-option label="套餐卡商城" :value="3" />
                <el-option label="AI智能套餐卡商城" :value="4" />
                <el-option label="双选商城" :value="5" />
                <el-option label="一件代发商城" :value="7" />
                <el-option label="小程序商城" :value="6" />
              </el-select>
            </el-form-item>
            <el-form-item label="关键词">
              <el-input style="width: 300px" v-model="kw" size="small" placeholder="请输入关键字进行搜索" clearable>
                <el-select style="width: 110px;" v-model="params.key_words" slot="prepend">
                  <el-option label="子订单编号" value="order_sn"></el-option>
                  <el-option label="关联总订单号" value="trade_sn"></el-option>
                  <el-option label="下单账号" value="mobile_account"></el-option>
                  <el-option label="商品名称" value="goods_name"></el-option>
                  <el-option label="物流单号" value="ship_no"></el-option>
                  <el-option label="供应商名称" value="seller_name"></el-option>
                  <el-option label="收货人手机号" value="ship_mobile"></el-option>
                  <el-option label="储值卡号" value="card_number"></el-option>
                  <el-option label="站点名称" value="shop_name"></el-option>
                  <el-option label="订单来源" value="item_source"></el-option>
                  <el-option label="卡券名称" value="card_name"></el-option>
                  <el-option label="礼包名称" value="shop_combo_name"></el-option>
                </el-select>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button @click="GET_OrderList(currentTab, 'search')" size="small" type="primary"
                :disabled="loading">搜索</el-button>
            </el-form-item>
            <div class="col"></div>
            <el-form-item>
              <el-button @click="handleExportOrders" size="small" type="primary" v-if="currentTab === '0'"
                :disabled="loading">导出全部订单</el-button>
            </el-form-item>
          </template>
          <template slot="table-columns">
            <el-table-column label="关联总订单号" width="140" fixed="left" align="center">
              <template slot-scope="{row}">
                <span class="ml-2">{{ row.trade_sn }}</span>
              </template>
            </el-table-column>
            <el-table-column label="子订单编号" width="140" fixed="left" align="center">
              <template slot-scope="{row}">
                <span class="ml-2">{{ row.sn }}</span>
              </template>
            </el-table-column>

            <el-table-column label="子订单金额" width="100" fixed="left" align="center">
              <template slot-scope="{row}">
                <span class="ml-2" v-if="row.order_price">{{ row.order_price | unitPrice('￥') }}</span>
              </template>
            </el-table-column>

            <el-table-column label="图片" width="60" class-name="goods-cover-wrapper" fixed="left" align="center">
              <template slot-scope="{row}">
                <el-popover placement="right" trigger="hover">
                  <img :src="row.goods_image" style="width: 300px;" alt />
                  <img slot="reference" :src="row.goods_image" class="goods-cover" alt />
                </el-popover>
              </template>
            </el-table-column>

            <el-table-column label="商品名称" show-overflow-tooltip width="200">
              <template slot-scope="{row}">
                <span class="ml-2">{{ row.goods_name.replaceAll('amp;', '').replaceAll(' ', '&nbsp;') }}</span>
              </template>
            </el-table-column>

            <el-table-column label="商品单价" width="100" align="center">
              <template slot-scope="{row}">
                <span class="ml-2" v-if="row.original_price">{{ row.original_price | unitPrice('￥') }}</span>
              </template>
            </el-table-column>

            <el-table-column label="商品数量" width="80" align="center">
              <template slot-scope="{row}">
                <span class="ml-2">{{ row.goods_num }}</span>
              </template>
            </el-table-column>

            <el-table-column label="售后状态" width="80" align="center">
              <template slot-scope="{row}">{{ MixinGetOrderStatus(row.service_status) }}</template>
            </el-table-column>

            <el-table-column label="物流状态" width="80" align="center">
              <template slot-scope="{row}">
                <span v-if="row.logistics_status_text">{{ row.logistics_status_text }}</span>
                <span v-else>{{ row.state === 2 ? '已发货' : (row.state === 3 ? '已收货' : '未发货') }}</span>
              </template>
            </el-table-column>

            <el-table-column label="物流单号" width="260" show-overflow-tooltip align="center">
              <template slot-scope="{row}">
                <template v-if="row.delivery">{{ row.delivery.logiName }}: {{ row.delivery.deliveryNo }}</template>
              </template>
            </el-table-column>
            <el-table-column label="收货人姓名" width="100" prop="ship_name" align="center" />
            <el-table-column label="收货人手机号" width="120" prop="ship_mobile" align="center" />
            <el-table-column label="收货地址" width="300" show-overflow-tooltip align="center">
              <template slot-scope="{row}">
                {{ row.ship_province + row.ship_city + row.ship_county + row.ship_town +
      row.ship_addr
                }}
              </template>
            </el-table-column>
            <el-table-column label="供应商名称" width="120" align="center" show-overflow-tooltip>
              <template slot-scope="{row}">{{ row.seller_name }}</template>
            </el-table-column>
            <el-table-column label="订单状态" width="100" align="center">
              <template slot-scope="{row}">
                <template>
                  {{ row.order_status_text }}
                  <div v-if="row.hangup_status === 1" style="color: red;">(店铺挂起)</div>
                  <div v-if="row.hangup_status === 2" style="color: red;">(企业挂起)</div>
                  <div v-if="row.hangup_status === 3" style="color: red;">(供应商挂起)</div>
                </template>
              </template>
            </el-table-column>
            <el-table-column label="订单来源" align="center" width="200">
              <template slot-scope="{row}">{{ row.order_original }}</template>
            </el-table-column>
            <el-table-column label="来源应用" align="center" width="200">
              <template slot-scope="{row}">
                <span v-if="row.mall_type == 2">现金商城</span>
                <span v-if="row.mall_type == 3">套餐卡商城</span>
                <span v-if="row.mall_type == 4">AI智能套餐卡商城</span>
                <span v-if="row.mall_type == 5">双选商城</span>
                <span v-if="row.mall_type == 6">小程序商城</span>
                <span v-if="row.mall_type == 7">一件代发商城</span>
              </template>
            </el-table-column>
            <el-table-column label="下单时间" align="center" width="160">
              <template slot-scope="{row}">{{ row.create_time | unixToDate }}</template>
            </el-table-column>

            <el-table-column label="下单账号" align="center" prop="mobile" width="150" show-overflow-tooltip
              class-name="font-family-tttgb">
              <template slot-scope="{row}">
                <p>{{ row.mobile || '' }}</p>
                <p>{{ row.login_account || '' }}</p>
              </template>
            </el-table-column>
            <el-table-column label="下单备注信息" align="center" width="160">
              <template slot-scope="{row}">
                <el-tooltip class="item" effect="dark" :content="row.remark" placement="top">
                  <div class="remark-box">
                    {{ row.remark }}
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column label="备注信息" align="center" width="150">
              <template v-if="row.remark_update_date && row.order_remark" slot-scope="{row}">
                <el-tooltip placement="top">
                  <div slot="content">
                    {{ row.remark_update_date | unixToDate }}
                    <br />
                    {{ row.order_remark.replaceAll("/n", "\n") }}
                  </div>
                  <div class="conceal">
                    {{ row.remark_update_date | unixToDate }}
                    <br />
                    {{ row.order_remark.replaceAll("/n", "\n") }}
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column label="操作" :width="170" fixed="right" align="center">
              <template slot-scope="{row}">
                <el-button type="primary" size="small" @click="showChildrenOrderDetail(row)">查看订单</el-button>
                <el-button type="primary" size="small" @click="openRemarkDialog(row)">备注</el-button>
              </template>
            </el-table-column>
          </template>
          <el-pagination v-if="tableData" slot="pagination" @size-change="handlePageSizeChange"
            @current-change="handlePageCurrentChange" :current-page="tableData.page_no" :page-size="tableData.page_size"
            :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background
            :total="tableData.data_total"></el-pagination>
        </en-table-layout>
      </el-tab-pane>
    </el-tabs>

    <x-dialog :proxy="remarkDialog">
      <div style="padding:10px">
        <el-input type="textarea" :autosize="{ minRows: 8, maxRows: 10 }" placeholder="请输入不超100字的备注信息" maxlength="100"
          v-model="remarkForm.remark"></el-input>
      </div>
    </x-dialog>
  </div>
</template>

<script>
import * as API_order from "@/api/order";
import { Foundation } from "@/../ui-utils";
import { mergeSkuList } from "@/utils";
import EnTableLayout from "../../../../ui-components/TableLayout/src/main";
import XDialog from "@/components/x-dialog/x-dialog";
import { $xDialog } from "@/components/x-dialog/dialog.proxy";
import TagJD from '@/assets/goods/tag-jd.png';
import TagJX from '@/assets/goods/tag-jx.png';
import TagZY from '@/assets/goods/tag-zy.png';
import statusZiti from '@/assets/goods/ziti.png';
export default {
  name: "orderLis",
  components: { EnTableLayout, XDialog },
  computed: {
    timeDefault () {
      var date = new Date();
      var s1 =
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        (date.getDate() - 1);
      return s1;
    }
  },
  filters: {
    tradeformFilter (status) {
      const statusMap = {
        1: "普通商品订单",
        2: "礼包订单",
        3: "计次卡订单",
        4: "宅配卡订单"
      };
      return statusMap[status];
    },
    paymentTypeFilter (val) {
      return val === "ONLINE" ? "在线支付" : "线下支付";
    }
  },
  data () {
    return {
      currentTab: "0",
      remarkDialog: $xDialog.create({
        title: "备注信息",
        width: "40vw",
        showClose: true,
        displayFooterExtras: true,
        beforeConfirm: this.editRemark
      }),
      // 备注信息
      remarkForm: {
        sn: "",
        platform: "",
        remark: "",
        member_name: JSON.parse(localStorage.getItem('admin_user')).username
      },
      keywords: {
        keys: [
          ["order_sn", "订单编号"],
          ["", "买家账号"],
          ["", "商品名称"],
          ["", "物流单号"],
          ["", "物流单号"],
          ["", "收货人手机号"]
        ]
      },

      // 列表loading状态
      loading: false,
      // 列表参数
      params: {
        page_no: 1,
        page_size: 20,
        key_words: "order_sn",
        order_sn: "",
        order_status: "ALL",
        mall_type: "",//来源应用
        trade_type: "",//订单类型
        payment_type: "",
        order_time_range: []
      },
      // 列表数据
      tableData: {},
      // 高级搜索数据
      advancedForm: {},
      kw: ""
    };
  },
  created () {
    this.handleInit();
  },
  mounted () {
    if (sessionStorage.getItem('orderListActiveName')) {
      this.currentTab = sessionStorage.getItem('orderListActiveName')
    }
    this.GET_OrderList(this.currentTab);
    this.$store.commit("DEL_VISITED_VIEWS", this.$route);
  },
  activated () {
    if (sessionStorage.getItem('orderListActiveName')) {
      this.currentTab = sessionStorage.getItem('orderListActiveName')
    }
    this.currentTab = sessionStorage.getItem('orderListActiveName')
    this.GET_OrderList(this.currentTab);
    this.$store.commit("DEL_VISITED_VIEWS", this.$route);
  },
  beforeDestroy () {
    sessionStorage.removeItem('orderListActiveName')
  },
  methods: {

    openRemarkDialog (row) {
      this.remarkDialog.display();
      this.remarkForm.sn = row.sn; //订单编号
      this.remarkForm.platform = 1; //平台编号
      //   this.remarkForm.remark = row.order_remark;
      console.log(row.order_remark);
      this.remarkForm.remark = //订单备注
        row.order_remark === null
          ? row.order_remark
          : row.order_remark.replaceAll("/n", "\n");
      this.remarkForm.member_name = JSON.parse(localStorage.getItem('admin_user')).username;
    },
    editRemark () {
      console.log(111)
      if (this.remarkForm.remark === "" || this.remarkForm.remark === null) {
        return this.$message.error("备注信息不能为空");
      }
      this.remarkForm.remark = this.remarkForm.remark.replace(/\n/g, "/n");
      console.log(this.remarkForm)
      API_order.editOrderRemark(this.remarkForm).then(res => {
        this.$message.success("保存成功");
        this.GET_OrderList(this.currentTab);
      });
    },
    orderSpanMethod ({ row, column, rowIndex, columnIndex }) {
      if (this.currentTab === "0") {
        if (columnIndex >= 2 && columnIndex <= 12) {
          return {
            rowspan: 1,
            colspan: 1
          };
        } else {
          return row.span;
        }
      } else {
        if (columnIndex >= 3 && columnIndex <= 13) {
          return {
            rowspan: 1,
            colspan: 1
          };
        } else {
          return row.span;
        }
      }
    },
    MixinGetOrderStatus (status) {
      switch (status) {
        case "NOT_APPLY":
          return "未申请";
        case "APPLY":
          return "已申请";
        case "PASS":
          return "审核通过";
        case "REFUSE":
          return "审核未通过";
        case "EXPIRED":
          return "已失效或不允许申请售后";
        case "REFUNDFAIL":
          return "退款失败";
        case "COMPLETED":
          return "完成";
        case "WAIT_SEND_BACK":
          return "待寄回";
        case "SEND_BACK":
          return "买家寄回";
        case "SELLER_SHIP":
          return "卖家发货";
        case "REFUSE_SHIP":
          return "卖家拒绝发货";
        default:
          return "";
      }
    },
    tabClicked () {
      this.kw = "";
      this.params = {
        page_no: 1,
        page_size: 20,
        key_words: "order_sn",
        order_sn: "",
        order_status: "ALL",
        payment_type: "",
        trade_type: "",//订单类型
        mall_type: "",
        order_time_range: []
      };
      this.GET_OrderList(this.currentTab);
    },
    handleInit () {
      let { type } = this.$route.query;
      switch (type) {
        case "2":
          // this.params.order_status = "ALL";
          // this.params.pay_status = "PAY_YES";
          var str = parseInt(new Date().getTime() / 1000);
          var str_s = Foundation.unixToDate(str, "yyyy-MM-dd");
          var arr = [];
          arr.push(str_s);
          arr.push(str_s);
          this.params.order_time_range = arr;
          let now_date = new Date(); //获取Date对象
          now_date.setHours(0); //设置小时
          now_date.setMinutes(0); //设置分钟
          now_date.setSeconds(0); //设置秒
          now_date.setMilliseconds(0); //设置毫妙
          let timestr = now_date.getTime(); //获取毫秒时间戳
          this.params = {
            ...this.params,
            start_time: timestr,
            end_time: timestr
          };
          break;
        case "3":
          this.params.order_status = "ALL";
          // this.params.pay_status = "PAY_YES";
          var arr = [];
          arr.push(this.timeDefault);
          arr.push(this.timeDefault);
          // 初始化搜索，默认为前一天
          this.params.order_time_range = arr;
          let now_date1 = new Date(); //获取Date对象
          now_date1.setHours(0); //设置小时
          now_date1.setMinutes(0); //设置分钟
          now_date1.setSeconds(0); //设置秒
          now_date1.setMilliseconds(0); //设置毫妙
          let timestr1 = now_date1.getTime() / 1000; //获取毫秒时间戳
          console.log(timestr1);
          this.params = {
            ...this.params,
            start_time: (timestr1 - 86400) * 1000,
            end_time: (timestr1 - 86400) * 1000
          };
          break;
        case "4":
          this.params.order_status = "COMPLETE";
          var arr = [];
          arr.push(this.timeDefault);
          arr.push(this.timeDefault);
          // 初始化搜索，默认为前一天
          this.params.order_time_range = arr;
          // var timestr = this.getTargetTime(this.timeDefault);
          this.params = {
            ...this.params,
            start_time: timestr - 86400000,
            end_time: timestr - 1000
          };
          break;
        default:
          break;
      }
    },
    getTargetTime (t) {
      var d = t.split(" ")[0],
        date = new Date();
      date.setYear(d.split("-")[0]);
      date.setMonth(d.split("-")[1] - 1);
      date.setDate(d.split("-")[2]);
      return date.getTime();
    },
    handleDateChange (val) {
      console.log(this.params.order_time_range);
      console.log(val);
      if (val) {
        this.params.start_time = parseInt(val[0].getTime());
        this.params.end_time = parseInt(val[1].getTime());
      } else {
        this.params.start_time = "";
        this.params.end_time = "";
      }
    },

    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.GET_OrderList(this.currentTab);
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_OrderList(this.currentTab);
    },

    /** 查看、操作订单 */
    handleOperateOrder (row) {
      this.$store.dispatch("addVisitedViews", this.$route);
      this.$router.push({
        name: "orderDetail",
        params: {
          sn: row.trade_sn,
        }
      });
    },
    /** 查看精选优品子订单详情 */
    showChildrenOrderDetail (row) {
      this.$store.dispatch("addVisitedViews", this.$route);
      this.$router.push({
        name: "childrenOrderDetail",
        params: {
          sn: row.sn
        }
      });
    },
    /** 导出订单 */
    handleExportOrders () {
      this.params[this.params.key_words] = this.kw;
      const params = this.MixinClone(this.params);
      if (params.start_time && params.end_time) {
        params.start_time = parseInt(params.start_time / 1000);
        params.end_time = parseInt(params.end_time / 1000) + 86399;
      }
      this.loading = true;
      delete this.params[this.params.key_words]
      delete params.order_time_range;
      delete params.key_words;
      // const { advancedForm: params } = this;
      this.loading = true;
      params.page_no = 1;
      params.page_size = 99999;
      API_order.getOrderList(params).then(response => {
        this.loading = false;
        const json = {
          sheet_name: "订单列表",
          sheet_values: response.data.map(item => ({
            订单编号: item.trade_sn,
            下单时间: Foundation.unixToDate(item.create_time),
            用户账号: (item.mobile || '') + (item.login_account ? ' ' + item.login_account : ''),
            用户备注信息: item.remark,
            订单总额: Foundation.formatPrice(
              item.account_pay_money +
              item.discount_card_coupon_price +
              item.discount_card_voucher_price +
              item.weixin_pay_money
            ),
            订单来源:
              (item.enterprise_name ? item.enterprise_name : "") +
              (item.shop_name ? " " + item.shop_name : ""),
            // 收货人: item.consignee_name,
            平台利润: Foundation.formatPrice(
              item.platform_price - item.cost_price
            ),
            订单状态: item.trade_status_text
            // 支付方式: "在线支付",
          }))
        };
        this.MixinExportJosnToExcel(json, "订单列表");
      });
    },

    /** 获取订单列表 */
    GET_OrderList (currentTab, type) {
      if (type === "search") {
        this.params.page_no = 1;
      }
      this.params[this.params.key_words] = this.kw;
      const params = this.MixinClone(this.params);
      if (params.start_time && params.end_time) {
        params.start_time = parseInt(params.start_time / 1000);
        params.end_time = parseInt(params.end_time / 1000) + 86399;
      }
      this.loading = true;
      delete this.params[this.params.key_words]
      delete params.order_time_range;
      delete params.key_words;
      if (currentTab === "0") {
        API_order.getOrderList(params)
          .then(response => {
            this.loading = false;
            this.tableData = response;
            this.tableData.data = mergeSkuList(response.data, 1);
            delete params[this.params.key_words]
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        if (params["member_name"]) {
          params["buyer_name"] = params["member_name"];
          delete params["member_name"];
        }
        switch (params["order_status"]) {
          case "PAID_OFF":
            params["order_status"] = "WAIT_SHIP";
            break;
          case "SHIPPED":
            params["order_status"] = "WAIT_ROG";
            break;
          case "CONFIRM":
            params["order_status"] = "WAIT_PAY";
            break;
        }
        API_order.getOrderChildrenList(params)
          .then(res => {
            this.loading = false;
            this.tableData = res;
            console.log(res);
            this.tableData.data = mergeSkuList(res.data, 1);
            delete params[this.params.key_words]
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    getRowTagImage (row) {
      if (row.delivery_method == 2 || row.delivery_method == 3) return statusZiti
      switch (row.order_source) {
        case 3:  // 自有商品
          return TagZY;
        case 1:  // 京东商品
          return TagJD;
        case 0:  // 普通商品（精选）
          return TagJX;
      }
    },
  }
};
</script>

<style lang="scss" scoped>
::v-deep .cell {
  padding-left: 0 !important;
}

::v-deep .el-table__fixed-body-wrapper {
  top: 34px !important;
}

.conceal {
  white-space: nowrap; //不换行
  overflow: hidden; //超出则隐藏
}

/deep/.el-tabs--card>.el-tabs__header {
  margin: 0;
}

.orderList-tab {
  background-color: #fff;

  .el-tab-pane {
    height: calc(100vh - 126px);
  }
}

.remark-box {
  max-width: 150px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
